import React from "react";

export default function Answer({
  userImg,
  userName,
  userDesignation,
  answer,
  address,
  affiliation,
  expected = false,
}) {
  return (
    <div className="answer-page">
      {expected ? <h5>ACCEPTED ANSWER</h5> : null}

      <div className="user-profile">
        <div
          className="imgadjust-detail"
          style={{ backgroundImage: `url(${userImg})` }}
        />

        <div>
          <h3>{userName}</h3>
          {address &&
            <>
              <h6>
                {userDesignation.length > 0 &&
                  userDesignation.split(",")[0] + " at " + affiliation}
              </h6>
              <h6>{address}</h6>
            </>
          }
          {(userDesignation && userDesignation.length > 0) && (
            <h6>specialities: {userDesignation}</h6>
          )}
        </div>
      </div>
      <p>{answer}</p>
    </div>
  );
}
