import React, { useState, useEffect } from "react";
import { Row, Col, Dropdown } from "react-bootstrap";
import user from "../assets/images/user.png";
import Helmet from "react-helmet";
import { useSelector } from "react-redux";
import { detailquestion_seperate } from "../../../api/actions/detailQuestion";
import unliked from "../assets/images/heart.svg";
import liked from "../assets/images/unlove.svg";
import Answer from "./answer.js";
import { Link } from "gatsby";
import InnerSocial from "./shares.js";
import RelatedQuestions from "./RelatedQuestions.js";
import Skeleton from "react-loading-skeleton";
import share from "../assets/images/ans.svg";
import answer from "../assets/images/message.svg";
import { favorite } from "../../../api/actions/favorite.js";
import { unfavorite } from "../../../api/actions/unfavorite.js";
import { getTokenFromLocalStorage } from "../../../api/utils/local-storage.js";
import queryString from "query-string";
import { useStaticQuery, graphql } from "gatsby";

export default function APIAnswer({ location }) {
  const detail_page = useSelector((state) => state.app.allquestions);
  const [detailquestion, setdeatailquestion] = useState({});
  const [loading, isloading] = useState(true);
  const question_url_id = queryString.parse(location.search).id;
  const [heart_pressed, setHeart_pressed] = useState();
  const authexist = getTokenFromLocalStorage();

  useEffect(() => {
    if (!!question_url_id) {
      const specific_question = detail_page.filter((data) => {
        if (data.id.toString() === question_url_id) {
          return data;
        }
      });

      if (specific_question.length == 1) {
        setdeatailquestion(specific_question[0]);

        if (specific_question[0].favorited_question) {
          setHeart_pressed(liked);
        } else {
          setHeart_pressed(unliked);
        }

        isloading(false);
      } else if (specific_question.length == 0) {
        (async () => {
          const result = await detailquestion_seperate(question_url_id);

          setdeatailquestion(result);
          if (result.favorited_question) {
            setHeart_pressed(liked);
          } else {
            setHeart_pressed(unliked);
          }
          isloading(false);
        })();
      }
    }
    else {
      setdeatailquestion(data);
    }
  }, []);

  //favorite and infoavorite
  //favorite and infoavorite
  const updatefavorite = async () => {
    if (detailquestion.favorited_question) {
      const check_like_status = await unfavorite(detailquestion.id);
      const result = await detailquestion_seperate(question_url_id);

      setdeatailquestion(result);
      if (result.favorited_question) {
        setHeart_pressed(liked);
      } else {
        setHeart_pressed(unliked);
      }
      isloading(false);
    } else {
      const check_dislike_status = await favorite(detailquestion.id);
      const result = await detailquestion_seperate(question_url_id);

      setdeatailquestion(result);
      if (result.favorited_question) {
        setHeart_pressed(liked);
      } else {
        setHeart_pressed(unliked);
      }
      isloading(false);
    }
  };

  var data = {
    question: {
      title: "What is the difference between PGS and PGD?",

      more_answers: {
        count: "6",
        answers: [
          {
            answer:
              "Pgs is a screening tool to look at the chromosomal content of the embryos whereas PGD is diagnostic where we look for a specific genetic abnormality/mutation. It is often confused but have very different purpose and meaning.",

            user: {
              name: "Santiago Munne, PhD",
              designation: "Geneticist, Researcher",
              img: user,
            },
          },
          {
            answer:
              "Pgs is a screening tool to look at the chromosomal content of the embryos whereas PGD is diagnostic where we look for a specific genetic abnormality/mutation. It is often confused but have very different purpose and meaning.",

            user: {
              name: "Santiago Munne, PhD",
              designation: "Geneticist, Researcher",
              img: user,
            },
          },
          {
            answer:
              "Pgs is a screening tool to look at the chromosomal content of the embryos whereas PGD is diagnostic where we look for a specific genetic abnormality/mutation. It is often confused but have very different purpose and meaning.",

            user: {
              name: "Santiago Munne, PhD",
              designation: "Geneticist, Researcher",
              img: user,
            },
          },
          {
            answer:
              "Pgs is a screening tool to look at the chromosomal content of the embryos whereas PGD is diagnostic where we look for a specific genetic abnormality/mutation. It is often confused but have very different purpose and meaning.",

            user: {
              name: "Santiago Munne, PhD",
              designation: "Geneticist, Researcher",
              img: user,
            },
          },
          {
            answer:
              "Pgs is a screening tool to look at the chromosomal content of the embryos whereas PGD is diagnostic where we look for a specific genetic abnormality/mutation. It is often confused but have very different purpose and meaning.",

            user: {
              name: "Santiago Munne, PhD",
              designation: "Geneticist, Researcher",
              img: user,
            },
          },
          {
            answer:
              "Pgs is a screening tool to look at the chromosomal content of the embryos whereas PGD is diagnostic where we look for a specific genetic abnormality/mutation. It is often confused but have very different purpose and meaning.",

            user: {
              name: "Santiago Munne, PhD",
              designation: "Geneticist, Researcher",
              img: user,
            },
          },
        ],
      },
    },
  };
  if (Object.keys(detailquestion).length == 0 && loading === false) {
    return (
      <div className="detail-answer">
        <div className="content">No data found</div>
      </div>
    );
  } else if (loading) {
    return (
      <div className="detail-answer">
        <div className="content">
          <Skeleton count={5} />
        </div>
      </div>
    );
  }

  var alldecipline = [];

  if (detailquestion.best_answer != null) {
    detailquestion.best_answer.created_by.specialities.map((x, y) => {
      if (y <= 1) {
        alldecipline.push(x.description);
      }
    });
  } else {
    if (detailquestion.answers.length > 0) {
      detailquestion.answers[0].created_by.specialities.map((x, y) => {
        if (y <= 1) {
          alldecipline.push(x.description);
        }
      });
    }
  }

  return (
    <div className="detail-answer">
      <div className="content">
        <Row>
          <Col sm={12} md={9}>
            <div className="question-box">
              {detailquestion.subtopics &&
                detailquestion.subtopics.length > 0 && (
                  <img
                    src={
                      detailquestion.subtopics.length > 0
                        ? detailquestion.subtopics[0].url
                        : null
                    }
                    className="tag"
                  />
                )}
              {!!authexist && (
                <img
                  src={
                    detailquestion.favorited_question
                      ? heart_pressed
                      : heart_pressed
                  }
                  onClick={updatefavorite}
                  className="tag pull-right-detail"
                />
              )}
              <h1>{detailquestion.description}</h1>
              <div className="social-countr found-socials">
                <div>
                  <img src={answer} />
                  <span>{detailquestion.answers_counter} ANSWERS</span>
                </div>

                {/*
                  <Dropdown>
                    <Dropdown.Toggle variant="" id="dropdown-basic">
                      <img src={share} />
                      <span> SHARE</span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <InnerSocial
                        link={`https://fertility.medanswers.com/questions/detail?id=${detailquestion.id}`}
                        title={detailquestion.description}
                        _id={detailquestion.id}
                      />
                    </Dropdown.Menu>
                  </Dropdown>
                */}
                <div></div>
              </div>
              <div className="expected-answer">
                <h2>ACCEPTED ANSWER</h2>
                <div className="user-profile">
                  <Answer
                    userImg={
                      detailquestion.best_answer != null
                        ? detailquestion.best_answer.created_by.url_avatar
                        : detailquestion.answers.length > 0
                          ? detailquestion.answers[0].created_by.url_avatar
                          : null
                    }
                    address={
                      detailquestion.best_answer != null
                        ? detailquestion.best_answer.created_by.city +
                        ", " +
                        detailquestion.best_answer.created_by.country
                        : detailquestion.answers.length > 0
                          ? detailquestion.answers[0].created_by.city +
                          ", " +
                          detailquestion.answers[0].created_by.country
                          : null
                    }
                    affiliation={
                      detailquestion.best_answer != null
                        ? detailquestion.best_answer.created_by.affiliation
                        : detailquestion.answers.length > 0
                          ? detailquestion.answers[0].created_by.affiliation
                          : null
                    }
                    userName={
                      detailquestion.best_answer != null
                        ? detailquestion.best_answer.created_by.first_name +
                        " " +
                        detailquestion.best_answer.created_by.last_name
                        : detailquestion.answers.length > 0
                          ? detailquestion.answers[0].created_by.first_name +
                          " " +
                          detailquestion.answers[0].created_by.last_name
                          : null
                    }
                    userDesignation={alldecipline.toString()}
                    answer={
                      detailquestion.best_answer != null
                        ? detailquestion.best_answer.description
                        : detailquestion.answers.length > 0
                          ? detailquestion.answers[0].description
                          : null
                    }
                  />
                </div>
              </div>
            </div>
            {detailquestion.answers_counter > 1 && (
              <div className="moreanswer-box">
                <h3>MORE ANSWERS ({detailquestion.answers_counter - 1})</h3>
                <div className="block">
                  {!!detailquestion.answers &&
                    detailquestion.answers.length > 0 &&
                    detailquestion.answers.map((data, counter) => {
                      var alldecipline = [];

                      data.created_by.specialities.map((x, y) => {
                        if (y <= 1) {
                          alldecipline.push(x.description);
                        }
                      });

                      return (
                        <>
                          {counter !== 0 && (
                            <Answer
                              userImg={
                                data.created_by != null
                                  ? data.created_by.url_avatar
                                  : null
                              }
                              affiliation={
                                data.created_by != null
                                  ? data.created_by.affiliation
                                  : null
                              }
                              userName={
                                data.created_by != null
                                  ? data.created_by.first_name +
                                  " " +
                                  data.created_by.last_name
                                  : null
                              }
                              address={
                                data.created_by != null
                                  ? data.created_by.city +
                                  ", " +
                                  data.created_by.country
                                  : null
                              }
                              userDesignation={alldecipline.toString()}
                              answer={data.description}
                            />
                          )}
                        </>
                      );
                    })}
                </div>
              </div>
            )}
          </Col>

          {/* <Col sm={12} md={3}>
            <RelatedQuestions content={data.question.more_answers.answers} />
          </Col>
                */}
        </Row>
      </div>
    </div>
  );
}
