import React, { useState, useEffect } from "react";
import { Row, Col, Dropdown } from "react-bootstrap";
import user from "../assets/images/user.png";
import Helmet from "react-helmet";
import { useSelector } from "react-redux";
import { detailquestion_seperate } from "../../../api/actions/detailQuestion";
import unliked from "../assets/images/heart.svg";
import liked from "../assets/images/unlove.svg";
import Answer from "./answer.js";
import { Link } from "gatsby";
import InnerSocial from "./shares.js";
import RelatedQuestions from "./RelatedQuestions.js";
import Skeleton from "react-loading-skeleton";
import share from "../assets/images/ans.svg";
import answer from "../assets/images/message.svg";
import { favorite } from "../../../api/actions/favorite.js";
import { unfavorite } from "../../../api/actions/unfavorite.js";
import { getTokenFromLocalStorage } from "../../../api/utils/local-storage.js";
import queryString from "query-string";
import { useStaticQuery, graphql } from "gatsby";
import SEO from "../../layout/seo";
import { getTextFromHTML } from '../../../utils/utils'

export default function CMSAnswer({ location }) {
    const detail_page = useSelector((state) => state.app.allquestions);
    const [detailquestion, setdeatailquestion] = useState(null);
    const [bestAnswer, setBestAnswer] = useState(null);
    const [loading, isloading] = useState(true);
    const question_url_id = queryString.parse(location.search).id;
    const [heart_pressed, setHeart_pressed] = useState();
    const authexist = getTokenFromLocalStorage();
    const searchQuery = queryString.parse(location.search)

    const data = useStaticQuery(graphql`
        query MyQuery3 {
            allCosmicjsPopularQuestions {
                nodes {
                    metadata {
                        answer
                        question
                        user
                        id
                        user_avatar {
                            url
                        }
                        user_affiliation
                        user_location
                        user_name
                        user_specialities
                        subtopic_icon
                        meta_image {
                            url
                        }
                    }
                }
            }
        
    }
  `);

    console.log("DATA: ", data)
    console.log("DATA DETAIL: ", detailquestion)

    useEffect(() => {
        let details = data.allCosmicjsPopularQuestions.nodes.find(que => { console.log("DATA A", que); return que.metadata.id == searchQuery.id }).metadata

        setdeatailquestion(details)
    }, []);

    //favorite and infoavorite
    //favorite and infoavorite
    const updatefavorite = async () => {
        if (detailquestion.favorited_question) {
            const check_like_status = await unfavorite(detailquestion.id);
            const result = await detailquestion_seperate(question_url_id);

            setdeatailquestion(result);
            if (result.favorited_question) {
                setHeart_pressed(liked);
            } else {
                setHeart_pressed(unliked);
            }
            isloading(false);
        } else {
            const check_dislike_status = await favorite(detailquestion.id);
            const result = await detailquestion_seperate(question_url_id);

            setdeatailquestion(result);
            if (result.favorited_question) {
                setHeart_pressed(liked);
            } else {
                setHeart_pressed(unliked);
            }
            isloading(false);
        }
    };

    return (
        <>
            <SEO
                title={
                    detailquestion?.question?.length > 150 ?
                        getTextFromHTML(detailquestion?.question).slice(0, 150) + "..." :
                        getTextFromHTML(detailquestion?.question)
                }
                description={
                    detailquestion?.answer?.length > 150 ?
                        getTextFromHTML(detailquestion?.answer).slice(0, 150) + "..." :
                        getTextFromHTML(detailquestion?.answer)
                }
                image={detailquestion?.meta_image?.url}
            />
            {!detailquestion ? null :
                <div className="detail-answer">
                    <div className="content">
                        <Row>
                            <Col sm={12} md={9}>
                                <div className="question-box">
                                    {detailquestion.subtopics &&
                                        detailquestion.subtopics.length > 0 && (
                                            <img
                                                src={
                                                    detailquestion.topic_icon || null
                                                }
                                                className="tag"
                                            />
                                        )}
                                    <h1>{detailquestion.question}</h1>
                                    <div className="social-countr found-socials">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="" id="dropdown-basic">
                                                <img src={share} />
                                                <span> SHARE</span>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <InnerSocial
                                                    link={`https://fertility.medanswers.com/questions/detail?id=${detailquestion.id}`}
                                                    title={detailquestion.answer}
                                                    _id={detailquestion.id}
                                                />
                                            </Dropdown.Menu>
                                        </Dropdown>

                                        <div></div>
                                    </div>
                                    <div className="expected-answer">
                                        <h2>ACCEPTED ANSWER</h2>
                                        <div className="user-profile">
                                            <Answer
                                                userImg={detailquestion?.user_avatar?.url || null}
                                                address={detailquestion?.user_location || ""}
                                                affiliation={detailquestion?.user_affiliations || ""}
                                                userName={detailquestion?.user_name || ""}
                                                userDesignation={detailquestion?.user_specialities || ""}
                                                answer={detailquestion?.answer ? getTextFromHTML(detailquestion?.answer) : ""}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            }
        </>
    );
}
