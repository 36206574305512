import API from "../utils/axios";
import { getTokenFromLocalStorage } from "../utils/local-storage";

var headers;

export const favorite = async (data) => {
  const authExist = getTokenFromLocalStorage();

  if (authExist != "") {
    headers = {
      "Content-Type": "application/json",
      authorization: `Token ${authExist} `,
    };
  }

  await API.post(`/questions/${data}/favorite`, undefined, {
    headers,
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};
