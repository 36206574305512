import React from 'react'
import CMSAnswer from './CMSAnswer'
import APIAnswer from './APIAnswer'

function index({ location }) {
    console.log("LOCATION : ", location)
    if (location?.state?.popular) {
        return <CMSAnswer location={location} />
    }
    else {
        return <APIAnswer location={location} />
    }
}

export default index
