import React from "react";
import Layout from "../../components/layout/layout";
import SEO from "../../components/layout/seo";
import Details from "../../components/Questions/detailsanswer";
import desktop from "../../components/Home/banner/assets/images/desktops.png";
export default function Detail({ location }) {
  return (
    <Layout location={location}>
      <SEO
        image={desktop}
        title="Question detail"
        description="Our experts are ready with clear answers - We're patients who were tired of sifting through iffy answers on patient forums. So we made it easy to get answers from the top specialists in the field."
      />
      <Details location={location} />
    </Layout>
  );
}
